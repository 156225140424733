import Head from "next/head";
import Footer from "../components/Footer";
import Image from "next/future/image";

import About from "../components/About";
import Layout from "../components/Layout";

export default function Home() {
  return (
    <Layout>
      <About />
    </Layout>
  );
}
