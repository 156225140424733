import React from "react";
import Head from "next/head";
import { useRouter } from "next/router";

export default function Layout({ children, ...customMeta }) {
  const router = useRouter();

  const meta = {
    title: "João Silva - software engineer",
    description: `I've been fiddling with software development for some time now. Get in touch with me to know more.`,
    image: "/images/logos/apple-touch-icon.png",
    type: "website",
    ...customMeta,
  };
  return (
    <div className="space-y-14 lg:space-y-24">
      <Head>
        <title>{meta.title}</title>
        <meta name="robots" content="follow, index" />
        <meta content={meta.description} name="description" />
        <meta
          property="og:url"
          content={`https://joaopgsilva.com${router.asPath}`}
        />
        <link
          rel="canonical"
          href={`https://joaopgsilva.com${router.asPath}`}
        />
        <meta property="og:type" content={meta.type} />
        <meta property="og:site_name" content="João Silva" />
        <meta property="og:description" content={meta.description} />
        <meta property="og:title" content={meta.title} />
        <meta property="og:image" content={meta.image} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@joaopgsilva" />
        <meta name="twitter:title" content={meta.title} />
        <meta name="twitter:description" content={meta.description} />
        <meta name="twitter:image" content={meta.image} />
        {meta?.date && (
          <meta property="article:published_time" content={meta?.date} />
        )}
      </Head>
      <main className="max-w-6xl mx-auto mt-16 antialiased">{children}</main>
    </div>
  );
}
